import { Check, MoveRight } from "lucide-react";
import React from "react";
import ketuo from "../../assets/images/ketuo.png";

const LoginForm = () => {
  const advertiserContents = [
    "Fast payouts",
    "Proven high conversion",
    "Access to exclusive affiliate promotions and product releases",
    "Original customised creative",
  ];
  return (
    <div className="login_advertiser_wrapper">
      <div className="chargezen_advertiser_container">
        <div className="header_logo_wrapper">
             <img src={`${process.env.REACT_APP_ENV_WORKER}/ChargeZenAffiliatesLogo2.png`}/>
        </div>
        <div className="body_content_wrapper">
          <div className="signup_link_wrapper">
            <a
              href="https://affiliates.chargezen.co/ketuoknifes"
              className="account_login_wrapper"
            >
              <div className="signup_link_text">Sign Up</div>
              <MoveRight />
            </a>
          </div>
          <h4 className="advertiser_subheader_text">
            Advertiser: Ketuo Knives
          </h4>
          <div className="advertisement_content_lists">
            {advertiserContents?.map((content) => {
              return (
                <div className="advertisement_list">
                  <div className="icon_wrapper">
                    <Check />
                  </div>
                  <div className="advertisement_text_wrapper">{content}</div>
                </div>
              );
            })}
          </div>
        </div>
    </div>
    </div>
  );
};

export default LoginForm;
