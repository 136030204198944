import React, { useState } from "react";

const Faq = (props) => {
  const [toggleFaqs, setToggleFaqs] = useState({});

  const faqs = [
    {
      id: 1,
      title: "What is the Ketuo Knives Affiliate Program?",
      body_title: `<p> The Ketuo Knives Affiliate Program is a program that invites review
      sites, online communities, bloggers, and other content website owners
      to promote products and services on their sites and earn commission on
      the sales they refer to&nbsp;
      <span>
        <a href="https://www.ketuousa.com/" target="_blank">
          <u>ketuousa.com</u>
        </a>
      </span>
      .</p>`,
    },
    {
      id: 2,
      title: "How do I earn commissions?",
      body_title: `<p>
      To earn commission, you need to apply for the affiliate program and
      sign up as an affiliate or a publisher. Once you are approved, you
      will receive our links which you can then promote. You will earn
      commission on any sales generated through these links.
    </p>`,
    },
    {
      id: 3,
      title: "Can a publisher promote any page on your site?",
      body_title: `<p>
      Our team provides links to relevant promotions, product list pages,
      brand pages, and product detail pages. Publishers can create custom
      links to any URL on
      <span>
        <a href="http://ketuousa.com" target="_blank">
          <u>ketuousa.com</u>
        </a>
      </span>
      &nbsp;that they find relevant for their web content through the
      related affiliate networks and their tool suite, as well as other 3rd
      party affiliate tools.
    </p>`,
    },
    {
      id: 4,
      title: "Can publishers post Ketuo Knives page copy on their site?",
      body_title: `<p>
        Publishers can use content&nbsp;that are distributed to affiliates
        through our product data feed. However, on a case-by-case basis,
        approval from the acting program manager may be required.
        </p>`,
    },
    {
      id: 5,
      title: "What types of promotions are not permitted?",
      body_title: `<p>
      To maintain a fair affiliate program, a number of promotion types are
      not permitted. </p>
      <p>These include, but are not limited to:
    </p>
    <ul>
      <li>
        <span></span>False links,
      </li>
      <li>
        <span></span>Creation of programs or scripts to simulate genuine
        purchase patterns, Persistent pop-ups and full screen popups,
      </li>
      <li>
        <span></span>Hiding links under other content,
      </li>
      <li>
        <span></span>Putting links directly into search engines,
      </li>
      <li>
        <span></span>Tricking people into clicking links,
      </li>
      <li>
        <span></span>Creating seemingly official Ketuo Knives websites in
        order to mislead people into purchasing,
      </li>
      <li>
        <span></span>Any actions in violation of the Ketuo Knives Affiliate
        Program terms.
      </li>
    </ul>
    <p>
      Ketuo Knives&nbsp;has the right&nbsp;to penalize those who violate the
      Ketuo Knives&nbsp;Affiliate Program agreement. &nbsp;
    </p>`,
    },
    {
      id: 6,
      title: "How do I get started?",
      body_title: `<p>
        To start the application process, click on the links provided above to
        join the Ketuo Knives Affiliate Program.
        </p>`,
    },
  ];
  return (
    <>
      <div className="loyalty_points_faqs" id="faq_wrapper_main">
        <div className="loyalty_points_faqs_container">
          <div className="row_wrapper">
            <div className="full_width">
              <h3 className="loyalty_points_faqs_heading heading_h2">
                Common questions & Answers
              </h3>
            </div>
          </div>
          <div className="row_wrapper">
            <div className="full_width">
              <div className="accordion">
                {faqs?.map((faq) => {
                  return (
                    <>
                      <div className="accordion_item">
                        <h3 className="accordion_item_header">
                          <div className="accordion_item_header_text">
                            {faq?.title}
                          </div>
                          <div
                            className="accordion_item_header_symbol"
                            onClick={() => {
                              setToggleFaqs({
                                [`faq_${faq.id}`]: !toggleFaqs[`faq_${faq.id}`],
                              });
                            }}
                          >
                            <div
                              className={`__symbol_vertical tohide${faq.id}`}
                            ></div>
                            <div className="__symbol_horizontal"></div>
                          </div>
                        </h3>
                      </div>
                      <div
                        className={`accordion_content faqAccordion${faq.id} ${
                          toggleFaqs[`faq_${faq.id}`] ? "" : "no_height"
                        }`}
                      >
                        <div
                          className="accordion_content_inner"
                          dangerouslySetInnerHTML={{ __html: faq?.body_title }}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
