import React from "react";
import AffilliatePage from "./AffilliatePage/Index";

const AffilliatePageLayout = () => {
  return (
    <>
      <div className="chargezen_affilliate_page_wrapper">
        <AffilliatePage />
      </div>
    </>
  );
};

export default AffilliatePageLayout;
