import React from "react";
import Faq from "./Faq";
import {
  CircleDollarSign,
  ScrollText,
  Share2,
  ShoppingCart,
} from "lucide-react";
import "./style.css";
import LoginForm from "./LoginForm";

const AffilliatePage = () => {
  const earnCommission = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-notebook-pen"
        >
          <path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4" />
          <path d="M2 6h4" />
          <path d="M2 10h4" />
          <path d="M2 14h4" />
          <path d="M2 18h4" />
          <path d="M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z" />
        </svg>
      ),
      html: `<p>Visit <a href='https://affiliates.chargezen.co/ketuoknifes'>ketuousa's affilite home</a> to  apply for <br/>the affiliate program</p>`,
    },
    {
      icon: <Share2 />,
      html: `<p>Share an <br/>affiliate link</p>`,
    },
    {
      icon: <ShoppingCart />,
      html: `<p>Get customers to click your <br/>link and make a purchase</p>`,
    },
    {
      icon: <ScrollText />,
      html: `<p>Wait for your order to be <br/>confirmed`,
    },
    {
      icon: <CircleDollarSign />,
      html: `<button class="get_your_commission_button">Get your commission</button>`,
    },
  ];
  return (
    <>
      <main id="chargezen_main_affiliate_page_container">
        <div class="chargezen_container container--narrow">
          <header class="chargezen_page__header page__header--centered">
            <h1 class="chargezen_page__title heading h1">
              Become An Affiliate
            </h1>
          </header>

          <div class="chargezen_page__content rte">
            <div>
              <p>
                <div className="main_header_wrapper">
                  <div className="main_header_content_wrapper">
                    <h2>Recommend KETUO Knives</h2>
                    <h1>Earn Commissions</h1>
                    <p className="header_text">
                      Earn 6% commission rate with potential for premium rates exceeding 8% for top-performing affiliates.
                    </p>
                  </div>
                </div>
              </p>
              <LoginForm />
              <p>
                <div className="how_to_earn_commission_container">
                  <h3 className="">How do I earn commissions?</h3>
                  <div className="earn_commission_list_wrapper">
                    {earnCommission.map((commission) => {
                      return (
                        <>
                          <div className="earn_commission_list">
                            <div className="icon_wrapper">
                              {commission?.icon}
                            </div>
                            <div
                              className="text_wrapper"
                              dangerouslySetInnerHTML={{
                                __html: commission?.html,
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </p>
            </div>
            <Faq />
          </div>
        </div>
      </main>
    </>
  );
};

export default AffilliatePage;
